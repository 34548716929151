@import "../../index.scss";


.main-wrapper{
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  background: $background;
  .main-sidebar{
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    padding: 10px;
    // background: $primary;
    
  }
  .main-body{
    flex : 1;
    padding: 10px 25px;
    overflow: auto;
    transition: 0.3s all;
  }
}
.personal_info {
  padding: 20px;
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
  background: $white;
  border-radius: $uiBorderRadius;
  box-shadow: $boxShadow;
  margin-bottom:20px;
  .notification,
  .settings {
    background-color: white;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 30px;
    width: 30px;
  }
  .self {
    display: flex;
    gap: 0.5rem;
    .self_name {
      color: #11142d;
      font-weight: bold;
      font-size: 10px;
    }
    .self_role {
      color: $text-light;
      font-size: 10px;
    }
    .self_logo {
      background-color: $primary;
      border-radius: 50%;
      height: 30px;
      width: 30px;
    }
  }
}
