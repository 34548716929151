$text: #11142d;
$text-light: #808191;
$text-lightmode: #b2b3bd;
// $primary: #6e69e4;
$primary: #2ca8ff;
// $primary-light: #6e69e41a;
$primary-light: #2ca8ff1a;
// $primary: #659447;
$secondary: #0049c6;
$accent: #ffa2c0;
$accent2: #ffce73;
$green: #7fba7a;
$green2: #a5cea1;
$green-light: #e0e9da;
$dark: #1b1d21;
$deactive: #808191;
$border: #e4e4e4;
$white: #ffffff;
$shadow: #e3e6ec;
$red: #ff754c;
$red-light: #fff6f3;
$link: #5f75ee;
$headline: #1b089a;
$background: #f7fafc;
$background-light: #fafafa;
$button-color: #fafafa;
$button-background: #808191;
$table-line: #00000008;
$lowPriorityColor : #538234;
$highPriorityColor : #C01717;
$mediumPriorityColor : #1B365C;
//ui
$uiHeight: 56px;
$uiBorder: 2px;
$uiBorderRadius: 10px;
$uiBorderRadiusThin: 5px;
$uiInputBorder: 2px solid $border;
$boxShadow: 0 0 2rem 0 rgba(136,152,170,.15);
// $boxShadow: 0px 5px 16px #00000014;
$h6: 18px;
$h5: 24px;
$h4: 32px;
$h3: 40px;
$h2: 48px;
$h1: 56px;

//media query
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-height: 600px){
  .react-responsive-modal-modal {
    height: auto !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  $uiHeight: 56px;
  $uiBorder: 2px;
  $uiBorderRadius: 8px;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
