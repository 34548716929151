@import '../../index.scss';
.qr_code_container{
    align-items: center;
  min-width: 350px;
  min-height: 350px;
  margin: 0.5em;
  align-items: center;
  justify-content: center;
  .qr_code_wrapper{
      padding-top:40px;
      align-items: center;
      justify-content: center;
  }
}