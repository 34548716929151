@import "../../index.scss";
.checkin_screen {
  height: 100%;
  .header_container {
    min-height: 100px;
    margin-bottom: 2em;
    .head {
      color: $text;
    }
    .header_wrapper {
      display: flex;
      align-items: center;
      .search_wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center !important;
        gap: 15px;
        padding: 20px;
        padding-right: 0;
        .add_field_container {
          width: 250px;
        }
        .add_user {
          padding: 15px;
          background-color: #6c5dd3;
          border-radius: 16px;
          color: white;
        }
      }
    }
  }
  
}
.c-stats-box{
  background-color: #00203FFF;
  color: white;
  border-radius: $uiBorderRadius;
  margin: 5px 0;
  text-align: center;
  cursor: pointer;
  // padding: 5px;
  // box-shadow: $boxShadow;
  overflow: hidden;
  .name{
    padding: 10px;
  }
  .assigned{
    background-color: #c5e0ad;
    color: black;
    border-left: 5px solid white;
    border-bottom: 3px solid white;
    padding: 5px;
    // border-radius: $uiBorderRadiusThin;
  }
  .active{
    background-color: #36d654;
    // background-color: #7ae243;
    // background-color: $green;
    color: white;
    border-left: 5px solid white;
    border-top: 3px solid white;
    padding: 5px;
    // border-radius: $uiBorderRadiusThin;
  }
}
