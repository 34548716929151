@import "../../index.scss";
.d-form-container{
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  .d-form-section-container{
    .d-form-section-label{
      font-size: 20px;
      font-weight: 600;
      
    }
    .d-field-container{
      margin: 10px 0;
      input{
        border : 2px solid $border;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
      }
      .d-field-label{
        font-weight: 500;
      }
    }
  }
}