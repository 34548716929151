@import "../../index.scss";

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
  .page-numbers {
    display: flex;
    height: 45px;
    align-items: end;
   
    button {
      border: 2px solid $border;
      color: $text-light;
      font-weight: bolder;
      margin: 0 3px;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: transparent;
      cursor: pointer;

      &.active {
        background-color: $primary;
        border: 2px solid $primary;
        color: #fff;
      }
    }
  }

  .page-info {
    font-size: 14px;
  }
}
