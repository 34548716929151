.table-icon-container {
  .desc {
    display: flex;
    background-color: white;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    .desc1 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
    }
    .sidebar_icon {
      font-size: larger;
      padding: 0px 5px;
      background-color: #fafafa;
      border-radius: 50%;
    }
  }
  .img-div {
    padding: 10px;
    border-radius: 50%;
  }
  .icon {
    filter: brightness(0) invert(1);
  }
}
