@import "../../index.scss";
#careers_screen{
  .career_filters {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // gap: 20px;
    cursor: pointer;
    background: #ffffff;
    border-radius: $uiBorderRadius;
    overflow: hidden;
    box-shadow: $boxShadow;
    .button{
      transition: 0.3s all;
      padding: 10px 20px;
    }
    .selected {
      transition: 0.3s all;
      cursor: pointer;
      background-color: $primary;
      color: $white;
    }
  }
}