@import "../../index.scss";
.warehouse_screen {
  height: 100%;
  .header_container {
  }
  .warehouse_filters_scroll {
    display: flex;
    overflow-x: scroll;
  }
}
