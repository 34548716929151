@import "../../index.scss";

.signup_screen {
  width: 100%;
  height: 100%;
  .left_arrow {
    cursor: pointer;
    position: absolute;
    top : 35px;
  }
  .signup_screen_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $white;
    border-left: 1px solid #ffffff3d;
    .signup_screen_container {
      min-width: 80%;
      max-width: 80%;
      .signup_head_container {
        .signup_head_wrapper {
          // color: #2f3367;
          .signup_head {
            font-size: 28px;
            font-weight: 700;
          }
          .signup_desc {
            font-size: 14px;
            font-weight: 400;
            padding: 0.5em 0;
            margin-top: 10px;
          }
        }
      }
      .forgot_head_wrapper {
        color: #2f3367;
        display: flex;
        flex-direction: column;
        align-items: center;
        .signup_head {
          font-size: 28px;
          font-weight: 700;
        }
        .signup_desc {
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          padding: 0.5em 0;
          margin-top: 10px;
        }
      }
      .signup_input_wrapper {
        padding-top: 1em;
        .signup_input_container {
          padding: 0.5em 0;
        }
        .submit_button_container {
          display: flex;
          justify-content: flex-end;
          padding-top: 1em;
        }
        .gotomail_button_container {
          display: flex;
          justify-content: center;
        }
      }
      .signup_links_container {
        display: flex;
        justify-content: center;
        .signup_link {
          font-size: 14px;
          font-weight: 400;
          color: $white;
          cursor: pointer;
        }
      }
    }
  }
}
