@import "../../index.scss";
.driver_screen {
  height: 100%;
  .header_container {
  }
  .driver_filters {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // gap: 20px;
    cursor: pointer;
    background: #ffffff;
    border-radius: $uiBorderRadius;
    overflow: hidden;
    box-shadow: $boxShadow;
    .button{
      transition: 0.3s all;
      padding: 10px 20px;
    }
    .selected {
      transition: 0.3s all;
      cursor: pointer;
      background-color: $primary;
      color: $white;
    }
  }
}
.reject_modal_container {
  display: "flex";
  justify-content: center;
  align-items: center;
  width: 420px;
  .reject_heading {
    font-size: 22px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
    padding: 0 10px;
    padding-bottom: 20px;
  }

  .reject_modal_document_content {
    font-size: 16px;
  }
  .rejection_document_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
  }

  .rejection_textarea {
    width: 400px;
    height: 80px;
  }
  .rejection_textarea_wrapper {
    outline: none;
    border: 2px solid #e4e4e4;
    background-color: #e4e4e4;
  }

  .rejection_textarea_active {
    outline: none;
    border: 2px solid $secondary;
    background-color: #ffffff;
  }

  .reject_modal_button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 30px;
    padding-bottom: 20px;

    .reject_button {
      cursor: pointer;
      height: 46px;
      border: 2px solid;
      border-radius: 16px;
      min-width: 166px;
      background-color: $primary;
      color: rgb(255, 255, 255);
      border-color: rgb(255, 255, 255);
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .reject_cancel_button {
      cursor: pointer;
      height: 46px;
      border: 2px solid;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      border-radius: 16px;
      min-width: 166px;
      background-color: rgb(255, 117, 76);
      color: white;
      border-color: rgb(255, 255, 255);
    }
  }
}
