@import '../../../index.scss';

.custom_modal {
  border-radius: 16px;
  overflow: scroll;
}
.react-responsive-modal-modal{
  border-radius: 30px;
}
@media only screen and (max-height: 600px) {
  .react-responsive-modal-modal {
   height: 250px !important;
  }
}
