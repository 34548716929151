@import "../../index.scss";

.select_address_container {
  position: relative;
  .input_container {
    .input {
      width: 100%;
      height: $uiHeight;
      border: $uiBorder solid $border;
      border-radius: $uiBorderRadius;
      padding-left: 1em;
      outline: none;
      // background-color: $border;
    }
    .input_textarea {
      height: 100px;
      padding: 0.5em;
    }
    .input:focus {
      border: $uiBorder solid $primary;
      border-radius: $uiBorderRadius;
      background-color: $white;
    }
    .input_wide {
      width: 98%;
    }
    .input_short {
      width: fit-content;
    }
    .label_container {
      color: $text;
      padding: 0.5em 0em;
    }
    .required {
      color: red;
    }
  }
  .select_address_body {
    position: absolute;
    width: 100%;
    // min-height: 100px;
    max-height: 300px;
    top: 75px;
    background-color: $white;
    border-radius: 18px;
    visibility: hidden;
    box-shadow: 0px 16px 30px rgba(202, 202, 202, 0.25);
    padding-bottom: 1em;
    overflow: auto;
    .custom_input_body_container {
      margin: 1em;
      height: 100%;
      overflow: scroll;
      .select_input_container {
        .no_data_found {
          font-size: 16px;
          text-align: center;
        }
        .select_input_wrapper {
          .add_button_container {
            display: flex;
            align-items: center;
            display: flex;
            justify-content: space-evenly;
          }
          .options_wrapper {
            padding-top: 1em;
            .options_container {
              padding: 0.5em;
              cursor: pointer;
              border-radius: 8px;
              .address_list_wrapper {
                display: flex;
                .address_list_name {
                  font-weight: bold;
                  margin-right: 10px;
                  font-size: 12px;
                  min-width: 150px;
                }
                .address_list_address {
                  font-size: 10px;
                }
              }
            }
            .options_container:hover {
              background-color: $border;
            }
          }
          // driver
          .driver_wrapper {
            display: flex;
            align-items: center;
            padding: 5px;
            cursor: pointer;
            border-radius: 8px;
            .driver_image_conainer {
              width: 40px;
              height: 40px;
              .driver_image {
                width: 100%;
                // height: 100%;
                border-radius: 50%;
              }
            }
            .driver_name_wrapper {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              .driver_name_container {
                padding-left: 0.8em;
                width: 70%;
                display: flex;
                flex-direction: row;
                align-items: center;
              }
              .driver_id_container {
                padding-left: 0.5em;
                color: $text-light;
                font-size: 14px;
                font-weight: 500;
              }
              .driver_label_container {
                color: $text-light;
                font-size: 14px;
                font-weight: 500;
                width: 30%;
              }
            }
          }
          .driver_wrapper:hover {
            background-color: $border;
          }
          //job
          .job_wrapper {
            display: flex;
            align-items: center;
            padding: 5px;
            cursor: pointer;
            border-radius: 8px;
            .job_name_wrapper {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              .job_name_container {
                padding-left: 0.8em;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
              }
            }
          }
          .job_wrapper:hover {
            background-color: $border;
          }
          // organization
          .organization_wrapper {
            display: flex;
            align-items: center;
            padding: 5px;
            cursor: pointer;
            border-radius: 8px;
            .organization_image_conainer {
              width: 40px;
              height: 40px;
              .organization_image {
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
          }
          .organization_wrapper:hover {
            background-color: $border;
          }
          // store
          .options_wrapper {
            .store_name_container {
              padding: 0.5em;
              cursor: pointer;
              border-radius: 8px;
              .menu {
                padding: 5px;
              }
            }
            .store_name_container:hover {
              background-color: $border;
              padding: 5px;
            }
          }

          //user
          .user_wrapper {
            display: flex;
            align-items: center;
            padding: 5px;
            cursor: pointer;
            border-radius: 8px;
            .user_image_conainer {
              width: 40px;
              height: 40px;
              .user_image {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            .user_name_container {
              padding-left: 0.5em;
            }
            .user_id_container {
              padding-left: 0.5em;
              color: $text-light;
              font-size: 14px;
              font-weight: 500;
            }
            .user_label_container {
              color: $text-light;
              font-size: 14px;
              font-weight: 500;
            }
          }
          .user_wrapper:hover {
            background-color: $border;
          }
          //vendor
          .vendor_wrapper {
            display: flex;
            align-items: center;
            padding: 5px;
            cursor: pointer;
            border-radius: 8px;
          }
          .vendor_wrapper:hover {
            background-color: $border;
          }
        }
      }
    }
  }
  .visible_body {
    z-index: 2;
    visibility: visible;
  }
}
