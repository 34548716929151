.select_address {
  position: relative;
  width: 100%;
  font-family: "Arial", sans-serif;

  .select_address_container {
    position: relative;
    width: 100%;
  }

  .input_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .label_container {
    margin-bottom: 8px;
    font-size: 14px;
  }

  .field_wrap {
    position: relative;
    width: 100%;
  }

  .input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
  }

  .select_address_body {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    z-index: 2;

    &.visible_body {
      display: block;
    }
  }

  .options_wrapper {
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    padding: 8px;

    .user_wrapper {
      display: flex;
      align-items: center;
      padding: 8px;
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
      }

      &.selected {
        background-color: #e0e0e0;
      }

      .checkbox {
        margin-right: 8px;
      }
    }

    .no_data_found {
      padding: 8px;
      color: #777;
    }
  }
}
