@import "../../../index.scss";
.search_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $white;
  box-shadow: $boxShadow;
  border-radius: $uiBorderRadius;
  margin-right: 30px;
  padding-left: 20px;
  min-width: 300px;
  .search_icon_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search {
    padding: 15px 30px;
    padding-left: 10px;
    border: none;
    border-radius: 16px;
    width: 100%;
    background-color: transparent;
    &:focus {
      outline: none;
      border: none;
    }
  }
  ::placeholder {
    color: $deactive;
  }
}
