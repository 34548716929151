@import "../../index.scss";
.table_container {
  background-color: white;
  display: flex;
  align-self: center;
  display: block;
  overflow: auto;
  position: static;
  border-radius: $uiBorderRadius;
  box-shadow: $boxShadow;
  border : 1px solid #eff2f5;
  padding: 20px;
  .table_wrapper {
    display: table;
    width: 100%;
    height: 100%;
    // overflow: hidden;
    .table_row {
      display: table-row;
      .table_cell {
        table-layout: auto;
        width: 120px;
        text-align: center;
        .table_head_text {
          text-align: center;
        }
      }
      .table_cell_image_wrapper {
        display: flex;
        align-items: center;
        // justify-content: center;
        border-bottom: none;
        .body1 {
          flex:  1;
        }
        .table_cell_image_container {
          .body1 {
            text-align: center;
            font-size: 13px;
          }
          .table_cell_image {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            margin-right: 1em;
          }
        }
      }
      .table_cell {
        display: table-cell;
        padding: 0px 10px;
        border-bottom: 1px solid $table-line;
        vertical-align: middle;
        // text-align: center;
        .action_wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          .action_button_container {
            padding-right: 15px;
            .action_btn_wrapper {
              display: flex;
              align-items: center;
              .action_btn_text {
                cursor: pointer;
                margin-right: 10px;
                padding: 5px 10px;
                border-radius: 10px;
                color: $button-color;
                font-size: 12px;
                background-color: $secondary;
              }
            }
          }
        }
      }
      .table_head_text {
        text-transform: capitalize;
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
        padding: 15px 10px;
        // border-radius: $uiBorderRadius $uiBorderRadius 0 0;
      }
    }
    .table_head {
      display: table-header-group;
      background-color: #f6f9fc;
      // .table_row {
      //   border: solid 5px green;
      // }
    }
    .table_body {
      display: table-row-group;
      .no_data_found {
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
        padding: 30px;
        min-width: 200px;
      }
    }
  }
  .load_more {
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-bottom: 50px;
  }
  .no_data_found {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    padding: 30px;
    min-width: 200px;
  }
}
