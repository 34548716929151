@import "../../../index.scss";
.filter_container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin-right: 30px;
  padding-left: 20px;
  .filter_icon_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filter {
    padding: 15px 30px;
    padding-left: 10px;
    border: none;
    border-radius: 16px;
    background-color: transparent;
    &:focus {
      outline: none;
      border: none;
    }
  }
}
