@import "../../../index.scss";

.sidebar_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $white;
  border-radius: 10px;
  // padding: 10px;
  box-shadow: $boxShadow;
  user-select: none;
  .app_logo_container {
    border-radius: $uiBorderRadius $uiBorderRadius 0 0 ;
    background-color: $primary;
    padding: 20px 0;
  }
  .app_logo_image {
    width: 150px;
    height: 50px;
  }

  .sidebar_item_container {
    border-radius: $uiBorderRadiusThin;
    cursor: pointer;
    color: $text-light;
    padding: 15px 10px;
    transition: 0.3s all;
    margin: 10px;
    &.active{
      transition: 0.3s all;
      padding: 15px 20px;
    }
    .sidebar_item_wrapper {
      display: flex;
      // width: 100%;
      // height: 60px;
      align-items: center;
      
      gap: 12px;
      
      .sidebar_icon_container {
        padding-right: 0px;
        display: flex;
      }

      .subtab_label_container {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 70px;

        .sidebar_label {
          font-size: 14px;
        }
      }
    }
  }

  .sidebar_item_scroll {
    // padding: 15px 0;
    flex: 1;
    overflow: auto;
  }

  .sidebar_item_container:hover {
    // background-color: $primary;
    // color: white;
    // .sidebar_icon {
    //   filter: brightness(0) invert(1);
    // }
  }

  .active {
    background-color: $primary;
    color: white !important;

    .sidebar_icon {
      filter: brightness(0) invert(1);
    }
  }
}

.sub {
  margin-left: 25px;
}

.sidebar_item_container_sub {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
}

@media only screen and (min-height: 650px) and (max-height: 768px) {
  .sidebar_item_wrapper {
  }
}

@media only screen and (max-height: 649px) {
  .sidebar_item_wrapper {
    
  }
}

@media only screen and (min-height: 769px) and (max-height: 900px) {
  .sidebar_item_wrapper {
    
  }
}

@media only screen and (min-height: 901px) and (max-height: 1080px) {
  .sidebar_item_wrapper {
    
  }
}

.sidebar_city_filter_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-left: 2em;
  padding-right: 15px;
  margin: 5px;
  height: 5vh;

  .city_filter {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

.city_filter_divider {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e4e4e4;
  margin-top: 12px;
  margin-right: 30px;
  margin-left: 30px;
  margin-bottom: 10px;
}