@import "../../index.scss";

.access_control_main_container {
    // display: flex;
    // flex-direction: row;
    padding: 10px;

    .access-control-heading {
        font-family: 'Poppins';
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }

    .inner_column {
        flex: 1;
        margin: 5px;

        .team-list-box {
            width: "100%";
            height: 55px;
            border-radius: 5px;
            margin-bottom: 4px;

            .team-heading {
                padding: 16px;
                font-size: 14px;
                font-weight: bold;

            }
        }

        .control-list-box {
            display: flex;
            align-items: center;
            padding: 15px;
            background-color: #2CA8FF;
            width: "100%";
            height: 55px;
            border-radius: 5px;
            margin-bottom: 4px;
            cursor: pointer;
            .control-title {
                margin-left: 15px;
                margin-bottom: 0px;
                font-size: 14px;
                font-weight: bold;
            }
        }

        .custom-checkbox .p-checkbox-icon {
            border-color: transparent;
            width: 14px;
            height: 14px;
        }

        .custom-checkbox .p-checkbox-box {
            background-color: #D9D9D9;
            border-color: transparent;
            width: 20px;
            height: 20px;
        }
    }

    .custom-checkbox .p-checkbox-box {
        border: 2px solid #2CA8FF;
        width: 20px;
        height: 20px;
    }

    .custom-checkbox .p-checkbox-label {
        margin-left: 5px;
    }


    .access_control_list {
        background-color: #e4e4e4;
        height: fit-content;
    }

    .access-control-heading {
        font-size: "28px";
    }
}